































































































































import { Component, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { namespace } from 'vuex-class';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import UrlType from '@/utils/enums/UrlType';
import CommunityUserUrl from '@/models/graphql/CommunityUserUrl';
import MyProfileInputText from '@/components/MyProfileInputText.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { mixins } from 'vue-class-component';
import SocialUrlWrapper from '@/components/wrappers/SocialUrlWrapper';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

const toastStore = namespace('ToastStore');
const communityUserUrlStore = namespace('CommunityUserUrlStore');

@Component({
  components: {
    FontAwesomeComponent,
    MyProfileInputText,
  },
})
export default class MyProfileContactsInfoWidget extends mixins(VueBaseWidget, SocialUrlWrapper, VueRegisterStoreWidget) {
  protected baseStoreName = 'MyProfileContactsInfoWidgetStore';

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @communityUserUrlStore.Action
  private createUserUrl!: (url: CommunityUserUrl) => Promise<CommunityUserUrl | undefined>;

  @communityUserUrlStore.Action
  private updateUserUrl!: (url: CommunityUserUrl) => Promise<CommunityUserUrl | undefined>;

  @communityUserUrlStore.Action
  private deleteUserUrl!: (uid: string) => Promise<CommunityUserUrl | undefined>;

  private urlType = UrlType;

  private defaultWebsite: CommunityUserUrl = {
    uid: '',
    type: '',
    name: this.urlType.WEBSITE,
    url: '',
    user: this.authUser,
  };

  private defaultSocial: CommunityUserUrl = {
    uid: '',
    type: '',
    name: this.urlType.SOCIAL,
    url: '',
    user: this.authUser,
  };

  private get userWebsites(): CommunityUserUrl[] {
    if (this.authUser) {
      if (this.authUser.urls) {
        const websites = this.authUser.urls.filter((url) => url.name === UrlType.WEBSITE);
        return websites.length > 0 ? websites : [this.defaultWebsite];
      }
      return [this.defaultWebsite];
    }
    return [];
  }

  private get userSocialMedias(): CommunityUserUrl[] {
    if (this.authUser) {
      if (this.authUser.urls) {
        const socials = this.authUser.urls.filter((url) => url.name === UrlType.SOCIAL);
        return socials.length > 0 ? socials : [this.defaultSocial];
      }
      return [this.defaultSocial];
    }
    return [];
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('isReadyToDisplay', { immediate: true })
  @Watch('authUser', { immediate: true })
  setFormUser(): void {
    if (this.isReadyToDisplay
    && this.authUser) {
      this.userContacts.uid = this.authUser.uid;
      this.userContacts.websiteUrls = this.userWebsites;
      this.userContacts.socialsUrls = this.userSocialMedias;
    }
  }

  private initDataNotSaved(): void {
    this.dataNotSaved = true;
  }

  private onUrlFieldChange(url: CommunityUserUrl, urlLocalIndex: number): void {
    this.onUrlInputChange(url, urlLocalIndex,
      {
        userUrls: this.authUser.urls,
        createUserUrl: this.addUrl,
        updateUserUrl: this.updateUserUrl,
      }, {
        onRemoveUrlClick: this.onRemoveUrlClick,
        showToast: this.showToast,
      });
  }

  private addUrl(url: CommunityUserUrl, index: number): void {
    const userUrl: CommunityUserUrl = {
      uid: '',
      name: url.name,
      type: url.type,
      url: url.url,
      user: this.authUser,
    };

    this.createUserUrl(userUrl)
      .then((response) => {
        if (this.authUser.urls && response) {
          this.authUser.urls.push(response);
          this.showToast(ToastActionType.UPDATE_URL);
          switch (userUrl.name) {
            case UrlType.WEBSITE:
              this.userContacts.websiteUrls[index].uid = response.uid;
              break;
            case UrlType.SOCIAL:
              this.userContacts.socialsUrls[index].uid = response.uid;
              break;
            default:
              break;
          }
        }
      });
  }

  private onAddUrlClick(urlType: UrlType): void {
    const userUrl: CommunityUserUrl = {
      uid: '',
      name: urlType,
      type: '',
      url: '',
      user: this.authUser,
    };

    switch (urlType) {
      case UrlType.WEBSITE:
        this.userContacts.websiteUrls.push(userUrl);
        break;
      case UrlType.SOCIAL:
        this.userContacts.socialsUrls.push(userUrl);
        break;
      default:
        break;
    }
  }

  private onRemoveUrlClick(uid: string, urlType: UrlType, urlIndexLocal: number): void {
    if (this.authUser.urls) {
      const index = this.authUser.urls.findIndex((el: CommunityUserUrl): boolean => el.uid === uid);
      if (index !== -1) {
        this.authUser.urls.splice(index, 1);
        this.deleteUserUrl(uid);
        this.showToast(ToastActionType.UPDATE_URL);
      }
      switch (urlType) {
        case UrlType.WEBSITE:
          this.userContacts.websiteUrls.splice(urlIndexLocal, 1);
          if (this.userContacts.websiteUrls.length === 0) {
            this.userContacts.websiteUrls.push(this.defaultWebsite);
          }
          break;
        case UrlType.SOCIAL:
          this.userContacts.socialsUrls.splice(urlIndexLocal, 1);
          if (this.userContacts.socialsUrls.length === 0) {
            this.userContacts.socialsUrls.push(this.defaultSocial);
          }
          break;
        default:
          break;
      }
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
